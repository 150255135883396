import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exception = _resolveComponent("exception")!

  return (_openBlock(), _createBlock(_component_exception, {
    title: "401 Error!",
    description: "抱歉，您没有登陆~",
    "has-goback": false
  }))
}